<template>
  <div id="header">
    <v-app-bar color="#319dbb" dense dark>
      <v-btn
        icon
        v-if="$router.currentRoute.fullPath != '/'"
        @click="$router.push('/')"
      >
        <v-icon>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="rgba(0,0,0,0)"
        href="https://www.machine2learn.com/"
      >
        <small
          >&copy; {{ new Date().getFullYear() }} — Machine2Learn B.V.</small
        >
        <img height="18vh" style="margin-left: 1em" src="../assets/logo.png" />
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  props: ['toolbarTitle'],
  mounted: function() {
    document.title = this.toolbarTitle;
  },
  data: function () {
    return {};
  }
};
</script>
