<template>
  <div id="component">
    <HeaderBar :toolbarTitle="title" />

    <v-alert class="mx-auto" type="error" prominent v-if="fatalError != ''">
      {{ fatalError }}
    </v-alert>

    <v-card class="mx-auto" v-else-if="processBundle == false">
      <file-pond
        class="pt-4 pr-4 pl-4"
        name="test"
        ref="pond"
        label-idle="Drag & drop your images or <span class='filepond--label-action'>Browse</span>"
        maxFileSize="10MB"
        acceptedFileTypes="image/*"
        allow-multiple="true"
        instantUpload="false"
        maxFiles="6"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="sendFiles()"
          color="#319dbb"
          class="font-weight-bold"
          text
          outlined
        >
          Next</v-btn
        >
      </v-card-actions>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>

    <v-card class="mx-auto" v-else>
      <v-carousel class height="auto">
        <v-carousel-item
          v-for="(item, i) in processBundle.visionPipelineDataList"
          :key="i"
          :src="'data:image/jpeg;base64,' + item.sceneDescription.visualization[0].data"
        ></v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import axios from "axios";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "DemoPage",
  data: function () {
    return {
      isLoading: true,
      title: "M2L Vision Demo — Upload Images",
      fatalError: "",
      demoToken: "",
      processBundle: false,
    };
  },
  mounted: function () {
    this.demoToken = this.$route.query.token;
    if (!this.demoToken) {
      this.fatalError = "Providing a demo token is required.";
    } else {
      const page = this;
      axios
        .get("/api/demoApp/" + this.demoToken)
        .then((response) => {
          this.isLoading = false;
          const demoMeta = response.data;
          if (demoMeta) {
            page.title = demoMeta.name + " Demo — Upload Images";
            document.title = page.title;
          }
        })
        .catch(function (err) {
          if (err.response && err.response.data && err.response.data.message) {
            page.fatalError = err.response.data.message;
          } else {
            page.fatalError = err;
          }
        });
    }
  },
  methods: {
    sendFiles: function () {
      if (this.$refs.pond.getFiles().length == 0) {
        alert("Please upload at least one image to continue.");
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      this.$refs.pond.getFiles().forEach(function (file, i) {
        formData.append(i + "_" + file.file.name, file.file);
      });

      const page = this;
      axios
        .post("/api/uploadImages/" + this.demoToken, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          page.isLoading = false;
          page.processBundle = response.data;
          // console.warn(processBundle);
          // page.title =
          //   page.processBundle.sessionMetadata.visionApp.name +
          //   "(" +
          //   page.processBundle.sessionMetadata.visionApp.version +
          //   ") — Results";
        })
        .catch((err) => {
          page.isLoading = false;
          console.error(err);
          page.fatalError = err.response.data.message;
        });
    },
  },
  components: {
    FilePond,
    HeaderBar,
  },
};
</script>

<style>
.filepond--item {
  width: calc(50% - 0.5em);
}
@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
  }
}

.filepond--file-action-button {
  cursor: auto;
}

.w-5 {
  max-width: 68px !important;
}
</style>