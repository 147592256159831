<template>
  <div id="component">
    <HeaderBar :toolbarTitle="title" />

    <v-form v-model="valid">
      <v-row class="mt-50 ml-5 mr-5">
        <v-col cols="12" sm="7" md="9">
          <v-text-field
            label="Private demo token"
            outlined
            v-model="privateDemoToken"
            :rules="[(v) => !!v || 'Demo token is required']"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="5" md="3">
          <div style="margin: 5px 0;">
            <v-btn
              :disabled="!valid"
              large
              text
              outlined
              color="#319dbb"
              class="font-weight-bold"
              @click="toPrivateDemo"
            >
              <v-icon>mdi-login-variant</v-icon> Go to the demo
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-row class="mt-50 ml-5 mr-5">
      <h2>Public Demos</h2>
    </v-row>
    <v-row class="ml-5 mr-5">
      <v-col
        cols="12" sm="12" md="6" lg="4"
        class="text-center"
        v-for="demo in demos"
        v-bind:key="demo.token"
      >
        <v-card outlined @click="toDemoPage(demo.token)">
          <div class="p-4">
            <div class="text-xs-center" style="width: 100%">
              <img :src="demo.image" height="120px" />
            </div>
            <h1 class="menu-text-1">{{ demo.title }}</h1>
            <div class="menu-text-2 mt-2" v-html="demo.description"></div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="toDemoPage(demo.token)"
                color="#319dbb"
                class="font-weight-bold"
                text
                >Run the demo</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'

import axios from "axios";
import demos from '../data/catalog.js'

export default {
  name: "DemoCatalog",
  components: {
    HeaderBar
  },
  data: function () {
    return {
      demos: demos,
      valid: false,
      privateDemoToken: "",
      title: "M2L Vision Demo Collection"
    };
  },
  methods: {
    toDemoPage: function (demoToken) {
      this.$router.push({ name: 'demo', query: { token: demoToken } });
    },
    toPrivateDemo: function () {
      if (this.privateDemoToken) {
        return this.toDemoPage(this.privateDemoToken);
      }
    },
  },
};
</script>
