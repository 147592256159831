import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import router from "./router";

Vue.config.productionTip = false
Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
})

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
